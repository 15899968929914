* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
  position: fixed;
  overflow: hidden;
}

#root {
  background: 
    url('https://firebasestorage.googleapis.com/v0/b/bettertinder-4ff52.appspot.com/o/Desktop%20-%202.png?alt=media&token=e24dafd5-7ea4-44bd-b684-ed441a505170')
    no-repeat fixed center;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto;
}

#root > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root > div > div {
  background-color: white;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 350px;
  height: 60vh;
  max-height: 570px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}
